import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/**
 * The Api Client for the ActorActionssController
 */
@Injectable({
  providedIn: 'root'
})
export class ActorActionsApiClient {

  constructor(private httpClient: HttpClient) { }


  /**
   *  Allows an Actor to reject humanity and embrace MONKE... i mean reject the Action.. yeah the Action ofcourse!
   * @param requestGuid relevant for what request to verify/Reject
   * @param actorInviteId Actor Rejecting
   * @returns (y)
   */
  Reject(requestGuid: string, actorInviteId: string, rejectionReason: string) {
    return this.httpClient.post(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/reject", { rejectionReason: rejectionReason });
  }
  /**
   * 
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  approve(requestGuid: string, actorInviteId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/approve", {});
  }

  /**
   * 
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  adopt(requestGuid: string, actorInviteId: string, skipAdoption: boolean, adoptionDate: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/adopt", { SkipAdoption: skipAdoption, AdoptionDate: adoptionDate });
  }
  /**
   * 
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  file(requestGuid: string, actorInviteId: string, receiverId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/file", { receiverId: receiverId });
  }



}
