import { Component } from '@angular/core';
import packageInfo from '../../../../../package.json';
import { PKIUrls } from 'src/app/constants/pkiurls.constants';
import { AppComponent } from '../../app/app.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  public version: string = packageInfo.version;
  public releasenotesUrl: string = PKIUrls.PatchNotes;

  constructor(public appComponent: AppComponent) {
    this.releasenotesUrl = this.appComponent.releasenotes ? (this.appComponent.releasenotesUrl ?? PKIUrls.PatchNotes) : PKIUrls.PatchNotes;
  }

  public ClickedOnVersion() {
    if (this.appComponent.releasenotes) {
      var urlToUse = this.releasenotesUrl;
      window.open(urlToUse, '_blank');
    }
  }
}
