import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InviteService } from 'src/app/services/invite.service';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';
import { TimeSynchronisationErrorComponent } from '../../components/general/time-synchronization-error/time-synchronisation-error.component';

@Component({
  templateUrl: './invite.page.html'
})
export class InvitePage implements OnInit {

  constructor(@Inject(DOCUMENT) private docElements: Document, private authenticationService: AuthenticationService, private inviteService: InviteService, private route: ActivatedRoute, private router:Router, private oidcSecurityService: OidcSecurityService, private loggingService: LoggingService, private modalService: NgbModal) { }

  inviteId: string = this.route.snapshot.paramMap.get('id') ?? "";
  inviteKey: string = this.route.snapshot.paramMap.get('key') ?? "";
  language!: string;
  whitelabelProperties = [];
  modalRef!: NgbModalRef;

  ngOnInit() {
    this.authenticationService.logout();
    if(!localStorage.hasOwnProperty('language')) {
      this.language = this.route.snapshot.queryParams['l'];
      localStorage.setItem("language", this.language);
      this.docElements.documentElement.lang = this.language;
    }
    sessionStorage.setItem("download", this.route.snapshot.queryParams['d'])
    if(this.authenticationService!=null) {
      this.authenticationService.validateClockSkew().then(valid =>{
      if(!valid) {
        this.TimeSynchronisationError();
        return;
      }
      this.authenticate();
      }).catch((error)=>{
        this.loggingService.logException(error);
      })  
    } 
  }

  TimeSynchronisationError() {
    let ngbModalOptions: NgbModalOptions = {
      keyboard : false,
      centered: true,
      size: 'md',
      backdrop: 'static',
      fullscreen: true,
      windowClass: 'overlay-fullscreen'
    };
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(TimeSynchronisationErrorComponent, ngbModalOptions);
  }

  authenticate() {
    let currentdomain: string = document.location.href.split('/')[2];
    let identityurl = environment.identityUrl;
    this.inviteService.getInvite(this.inviteId, this.inviteKey)
      .then(value => {
        lastValueFrom(value)
          .then(inviteData => {
            this.authorization(currentdomain, identityurl, inviteData);
          })
          .catch(() => {
            this.router.navigate(["/invite-not-found"])
              .catch(error => {
                this.loggingService.logException(error);
              });
          });
      })
      .catch(() => {
        this.router.navigate(["/invite-not-found"])
          .catch(error => {
            this.loggingService.logException(error);
          });
      });
  }

  authorization(currentdomain: string, identityurl: string, data: any) {
    if(this.route.snapshot.queryParams['d']?.toLowerCase() == "true"){
      sessionStorage.setItem("returnUrl", "/download/" + data.dossierId);
    }else{
      sessionStorage.setItem("returnUrl", "/view/" + data.dossierId);
    }
    if (data.idp && data.idp != 'Default') {
      this.oidcSecurityService.authorize(null!, { customParams: { 'acr_values': "idp:"+data.idp, 'login_hint': this.inviteId } });
    } else {
      this.oidcSecurityService.authorize(null!, { customParams: { 'login_hint': this.inviteId } });
    }
  }
}
